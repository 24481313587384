<template>
  <div class="retro-content">
    <div class="retro-content__title">
      Desired tournament:
    </div>
    <RetroCarousel />
    <div class="retro-content__season-select">
      <div>Tournament</div>
      <div>
        <BSelect v-model="seasonSelected" placeholder="Selecciona uno" expanded>
          <option v-for="season in availableSeasons" :key="season.id" :value="season">
            {{ season.name }}
          </option>
        </BSelect>
      </div>
    </div>
    <div v-if="seasonNews.length" class="retro-content__retro-data">
      <BCarousel :autoplay="false">
        <BCarouselItem v-for="(info, index) in seasonNews" :key="index">
          <RetroData :info="info" />
        </BCarouselItem>
      </BCarousel>
    </div>
  </div>
</template>

<script>
import { BSelect } from 'buefy/dist/esm/select';
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
import axios from 'axios';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'RetroContent',
  components: {
    BSelect,
    BCarousel,
    BCarouselItem,
    RetroCarousel: () => import('@/components/Sections/MediaPlayer/RetroCarousel'),
    RetroData: () => import('@/components/Sections/MediaPlayer/RetroData'),
  },
  props: {
    isRetroMode: {
      type: Boolean,
      default: false,
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      seasonSelected: {},
      availableSeasons: [],
      seasonNews: [],
    };
  },
  watch: {
    seasonSelected: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.setRetroSeason(newValue);
          this.fillRetroNews(newValue);
          this.getTeamsBySeasons({ seasonId: newValue.id });
          this.getPlayersBySeasons({ seasonId: newValue.id });
        }
      },
    },
    isRetroMode(newValue) {
      if (newValue) {
        this.seasonSelected = this.availableSeasons[0];
      }
    },
    isModalVisible: {
      immediate: true,
      async handler(newValue) {
        try {
          if (newValue && this.isRetroMode) {
            if (!this.availableSeasons.length) {
              await this.fillAvailableSeasons();
            }
            if (this.availableSeasons.length) {
              this.seasonSelected = this.availableSeasons[0];
            }
          }
        } catch (e) {
          throw new Error(e);
        }
      },
    },
  },
  methods: {
    ...mapActions('mediaGeneral', ['getTeamsBySeasons', 'getPlayersBySeasons']),
    ...mapMutations('mediaGeneral', ['setSeason', 'setRetroSeason', 'setRetroTournament']),
    async fillRetroNews(season) {
      try {
        this.seasonNews = await this.fetchSeasonNews(season);
      } catch (e) {
        throw new Error(e);
      }
    },
    async fillAvailableSeasons() {
      try {
        const tournamentsRetro = await this.fetchTournamentsRetro();
        const mxLeague = tournamentsRetro.find(tournament => tournament.tournament_id === 1);
        if (mxLeague) {
          mxLeague.seasons = mxLeague.seasons.map((season, index) => {
            const seasonAux = {
              id: season.season_id,
              name: season.season_name,
              index,
            };
            if (seasonAux.id === 1401) {
              seasonAux.related_seasons = [{ id: 1446 }, { id: 1456 }];
            }
            return seasonAux;
          });
          this.availableSeasons = mxLeague.seasons || [];
          this.setRetroTournament(mxLeague);
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async fetchTournamentsRetro() {
      try {
        const response = await axios.get(
          'https://malqisf9fg.execute-api.us-east-2.amazonaws.com/prod/retro/tournaments',
        );
        return response.data || [];
      } catch (e) {
        throw new Error(e);
      }
    },
    async fetchSeasonNews(season) {
      try {
        const response = await axios.get(
          `https://l45gfq4ume.execute-api.us-east-2.amazonaws.com/prod/retro/seasons/${season.id}/news`,
        );
        return response.data && response.data.news ? response.data.news : [];
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.retro-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 1.25rem;
    font-family: Avenir-Pro-Medium, sans-serif;
    color: #3e3e3e;
    margin-bottom: 0.8rem;
  }

  &__season-select {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0 1rem;

    & > div {
      &:first-child {
        margin: 0 0.5rem;
        color: #808285;
        font-size: 1rem;
        font-family: Avenir-Pro-Medium, sans-serif;
      }

      &:last-child {
        width: 100%;
      }
    }

    @media screen and (max-width: 460px) {
      flex-direction: column;
      align-items: flex-start;
      width: 80%;
      margin-top: 0.5rem;
    }
  }

  &__retro-data {
    width: 1052px;
    margin-bottom: 1rem;

    @media screen and (max-width: 1553px) {
      width: 100%;
      padding: 0 1rem;
    }
  }
}
</style>

<style lang="scss">
.retro-content {
  & > div.retro-content__retro-data {
    .carousel .carousel-indicator .indicator-item.is-active .indicator-style,
    .carousel .carousel-indicator .indicator-item .indicator-style:hover {
      background: #7ed321;
      border: 1px solid white;
    }
  }

  & .retro-content__retro-data {
    & > div.carousel {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > div.carousel-indicator.is-bottom {
        position: relative;
      }
    }
  }
}
</style>
